
<template>
  <div>
    <div class="mass-action-settings">

      <el-select
        style="width:200px"
        size="mini"
        v-model="actionData.segment"
        placeholder="Выберите тип комментария"
      >
        <el-option label="Цель" value="plan"></el-option>
        <el-option label="Факт" value="assessment"></el-option>
      </el-select>
      <el-row type="flex" style="margin-top: 20px">
        <div style="width:100%; margin-right:20px">
          <el-input
            v-model="actionData.text"
            type="textarea"
            :autosize="{ minRows: 3, maxRows: 6}"
            placeholder="Введите сообщение"
            size="mini"
            clearable
          >
          </el-input>
        </div>
        <el-upload
          ref="file-uploader"
          class="chat-dragger"
          drag
          action=""
          multiple
          :auto-upload="false"
          :on-exceed="onExceed"
        >
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">Перетащите или нажмите для загрузки файла</div>
        </el-upload>
      </el-row>

      <el-popconfirm
        confirm-button-text='Да'
        cancel-button-text='нет'
        icon="el-icon-question"
        title="Вы уверены, что хотите создать комментарии?"
        @confirm="doAction"
      >
        <el-button
          slot="reference"
          size="small"
          type="primary"
          :disabled="actionButtonDisabled"
        >
          Отправить
        </el-button>

      </el-popconfirm>

    </div>

    <mass-action-result
      v-if="result"
      :result="result"
    ></mass-action-result>

  </div>
</template>

<script>
import {ActionTemplateMixin} from "@/components/massActions/actionTemplates/ActionTemplateMixin";
import MassActionResult from "@/components/massActions/MassActionResult.vue";
import CommentsBlock from "@/components/comments/CommentsBlock.vue";

export default {
  name: "card-element-create-comment-action",
  mixins: [ActionTemplateMixin],
  components: {CommentsBlock, MassActionResult},

  computed: {
    actionButtonDisabled() {
      return !this.actionData.segment;
    }
  },

  data() {
    return {
      actionData:{
        segment: null,
        text: null,
      }
    }
  },
  methods: {
    doAction(){
      let data = {
        segment: this.actionData.segment,
        text: this.actionData.text,
      }
      let attachment = this.$refs['file-uploader']?.uploadFiles[0]?.raw;
      if( attachment ){
        data.attachment_name = this.$refs['file-uploader'].uploadFiles[0].raw.name;
        let reader = new FileReader();
        reader.onload = (evt) => {
          data.attachment = evt.target.result
            .replace('data:', '')
            .replace(/^.+,/, '');

          this.$emit('do-action', data);
        }
        reader.readAsDataURL(attachment);
      } else {
        this.$emit('do-action', data);
      }
    },

    onExceed(params) {
      this.$notify.error({
        title: 'Лимит',
        message: 'Не более одного элемента'
      });
    },
  },
}
</script>
